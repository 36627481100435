import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Flickity from "react-flickity-component"
import styled from "styled-components"
import { mq } from "../utils/presets"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import NewProjectsList from "../components/projects/NewProjectsList"
// import Projects from "../components/Projects"
// import IsotopeProjects from "../components/IsotopeProjects"
import ProjectsGrid from "../components/ProjectsGrid"

const flickityOptions = {
  initialIndex: 0,
  autoPlay: 6500,
  friction: 0.8,
  wrapAround: true,
  pageDots: false,
  lazyLoad: 1,
}

const ImageWrapper = styled.div`
  .gatsby-image-wrapper {
    width: 100%;
    object-fit: cover;
    height: calc(100vh - 195px);
    @media screen and (min-width: 480px) {
      height: calc(100vh - 210px);
    }
    ${mq.tablet} {
      height: calc(100vh - 230px);
    }
    ${mq.xl} {
      height: calc(100vh - 150px);
    }
  }
  height: calc(100vh - 195px);
  width: 100%;
  overflow: hidden;
  @media screen and (min-width: 480px) {
    height: calc(100vh - 210px);
  }
  ${mq.tablet} {
    height: calc(100vh - 230px);
  }
  ${mq.xl} {
    height: calc(100vh - 150px);
  }
  position: relative;
  /* overflow: hidden; */
  margin-top: 5.4rem;
  p {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-left: 1rem;
    color: white;
    text-shadow: 0 0 5px rgba(0, 0, 0, 1);
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
  .linkto {
    position: absolute;
    bottom: 0;
    right: 0;
    color: white;
    padding-right: 1rem;
    text-shadow: 0 0 5px rgba(0, 0, 0, 1);
    margin-bottom: 0.5rem;
  }
`

const Image = styled.div`
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`

const IndexPage = ({ data }) => {
  const page = data.page
  const slider = page?.homeSlider?.slider

  const projects = data.projects.nodes
  const architektur = data.architektur.nodes
  const stadtplanung = data.stadtplanung.nodes
  const wettbewerbe = data.wettbewerbe.nodes

  const seo = page?.seo
  const seoTitle = page?.seo?.opengraphTitle || page.title || ``
  const metaDesc = seo.metaDesc || ``
  const seoImage = page?.featuredImage?.node.localFile.childImageSharp.resize

  return (
    <Layout isFrontPage={page.isFrontPage}>
      <Seo
        title={page.title}
        seoTitle={seoTitle}
        description={metaDesc}
        image={seoImage}
        uri={page.uri}
      />{" "}
      <Flickity
        className="flickitycss"
        elementType={"div"} // default 'div'
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
      >
        {slider.map((slide, i) => {
          const sliderImage = {
            image: getImage(slide.bild?.localFile),
            alt: slide.bild?.altText || "",
          }

          return (
            <ImageWrapper key={i}>
              {!!sliderImage.image && (
                <Image>
                  <GatsbyImage
                    image={sliderImage.image}
                    alt={sliderImage.alt}
                  />
                </Image>
              )}
              <p>{slide.bild.alt_text}</p>
              <Link className="linkto" to={slide.link.url}>
                {slide.link.title}
              </Link>
            </ImageWrapper>
          )
        })}
      </Flickity>
      {/* <NewProjectsList /> */}
      <ProjectsGrid
        projects={projects}
        architektur={architektur}
        stadtplanung={stadtplanung}
        wettbewerbe={wettbewerbe}
      />
      {/* <Projects projects={projects} /> */}
      {/* <IsotopeProjects projects={projects} /> */}
      {/* <h1>{process.env.GATSBY_PAGE_SIZE}</h1>
      {projects.map((item, i) => {
        return <div key={i}>{item.title}</div>
      })} */}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    page: wpPage(slug: { regex: "/projekte/" }) {
      title
      content
      isFrontPage
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        opengraphTitle
        opengraphDescription
        schema {
          articleType
          pageType
          raw
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              resize(width: 1200, height: 627) {
                src
                tracedSVG
                width
                height
                aspectRatio
                originalName
              }
            }
          }
        }
      }
      # featuredImage {
      #   node {
      #     altText
      #     localFile {
      #       childImageSharp {
      #         gatsbyImageData(
      #           width: 2000
      #           placeholder: NONE
      #           formats: [AUTO, WEBP]
      #         )
      #       }
      #     }
      #   }
      # }
      homeSlider {
        slider {
          link {
            title
            url
            target
          }
          bild {
            slug
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }

    projects: allWpProjekt(sort: { fields: [date], order: DESC }) {
      nodes {
        title
        slug
        date
        id
        categories {
          nodes {
            name
          }
        }
        projekte {
          options {
            size
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 510
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }

    architektur: allWpProjekt(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Architektur" } } } }
      }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        title
        slug
        date
        id
        categories {
          nodes {
            name
          }
        }
        projekte {
          options {
            size
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 510
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }

    stadtplanung: allWpProjekt(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Stadtplanung" } } } }
      }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        title
        slug
        date
        id
        categories {
          nodes {
            name
          }
        }
        projekte {
          options {
            size
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 510
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }

    wettbewerbe: allWpProjekt(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Wettbewerbe" } } } }
      }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        title
        slug
        date
        id
        categories {
          nodes {
            name
          }
        }
        projekte {
          options {
            size
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 650
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
