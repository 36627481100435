import React from "react"
import styled from "styled-components"
import { mq } from "../utils/presets"
import Masonry from "react-masonry-component"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-light-svg-icons"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Tab } from "semantic-ui-react"

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  min-height: 600px;

  .menu {
    font-size: 0.7rem;
    ${mq.tablet} {
      font-size: 0.9rem;
    }
    margin: 1rem;
    text-align: center;
    .active {
      background: #efefef !important;
      border-color: lightgrey !important;
      opacity: 1 !important;
    }
    .item {
      /* display: inline-block; */
      background: white;
      border: 1px solid #dedede;
      opacity: 0.8;
      border-radius: 8px;
      padding: 0.3rem 0.5rem;
      &:hover,
      &:focus,
      &:active {
        cursor: pointer;
        background: #efefef !important;
        border-color: lightgrey !important;
      }
      &:first-child {
        margin-right: 0.5rem;
      }
      &:nth-child(2) {
        margin-right: 0.5rem;
      }
      &:nth-child(3) {
        margin-right: 0.5rem;
      }
      input {
        margin-right: 0.2rem;
      }
    }
  }

  li {
    list-style: none;
  }
  .grid {
    max-width: 1040px;
    margin: 0 auto;
    /* background: red; */
  }
  /* clearfix */
  .grid:after {
    content: "";
    display: block;
    clear: both;
    background: blue;
  }
  .grid-sizer {
    width: 100%;
  }

  .filter-container {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    font-size: 2rem;
  }

  .grid-item {
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
    object-fit: cover;
    border: 1px solid lightgrey;
    .linkitem {
      display: relative;
      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
        transition: all 0.2s;
      }
      .innerDiv {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        padding: 0.2rem 0.5rem;
        margin: 0.2rem;
        transition: all 0.4s;
        position: relative;
        opacity: 1;
        p {
          margin: 0;
        }
        ${mq.tablet} {
          color: white;
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 999;
          width: 100%;
          height: 100%;
          text-align: center;
          display: flex;
          flex-flow: column;
          justify-content: center;
          p {
            width: 100%;
            color: white;
            opacity: 1;
            font-weight: bold;
          }
        }
        svg {
          align-self: center;
          ${mq.tablet} {
            display: none;
          }
        }
      }
    }
  }
  ${mq.tablet} {
    .grid-sizer {
      width: calc(50% - 10px);
    }
    .grid-item {
      background: #f3f3f3;
      height: 195px;
      position: relative;
      width: calc(50% - 10px);
      &:hover,
      &:focus,
      &:active {
        background: black;
        transition: all 0.2s;
        .linkitem {
          opacity: 1;
        }
        .innerDiv {
          opacity: 1;
        }
        .gatsby-image-wrapper {
          opacity: 0.35;
        }
      }
    }
    .grid-item--width2 {
      width: calc(50% - 10px);
      height: 400px;
    }
    .grid-item--width3 {
      width: calc(100% - 10px);
      height: 300px;
    }
    .grid-item--width4 {
      width: calc(50% - 10px);
      height: 400px;
    }
  }
  ${mq.desktop} {
    .grid-sizer {
      width: calc(25% - 10px);
    }
    .grid-item {
      background: #f3f3f3;
      height: 220px;
      position: relative;
      width: calc(25% - 10px);
    }
    .grid-item--width2 {
      width: calc(25% - 10px);
      height: 450px;
    }
    .grid-item--width3 {
      width: calc(50% - 10px);
    }
    .grid-item--width4 {
      width: calc(50% - 10px);
      height: 450px;
    }
  }
`

const ProjectsGrid = ({ projects, architektur, stadtplanung, wettbewerbe }) => {
  return (
    <Wrapper>
      <Tab
        menu={{ secondary: true }}
        panes={[
          {
            menuItem: "Alle",
            render: () => (
              <div className="container">
                <Masonry
                  className={"grid"}
                  options={{
                    resize: true,
                    transitionDuration: 0,
                    // use outer width of grid-sizer for columnWidth
                    columnWidth: ".grid-sizer",
                    // do not use .grid-sizer in layout
                    itemSelector: ".grid-item",
                    percentPosition: true,
                    gutter: 10,
                  }}
                >
                  {/* .grid-sizer empty element, only used for element sizing */}
                  <div className="grid-sizer"></div>
                  {projects.map((project, i) => {
                    let gridSize = ""
                    if (project?.projekte?.options?.size === "1x1") {
                      gridSize = "grid-item"
                    }
                    if (project?.projekte?.options?.size === "1x2") {
                      gridSize = "grid-item--width2"
                    }
                    if (project?.projekte?.options?.size === "2x1") {
                      gridSize = "grid-item--width3"
                    }
                    if (project?.projekte?.options?.size === "2x2") {
                      gridSize = "grid-item--width4"
                    }

                    return (
                      <div className={"grid-item " + gridSize} key={i}>
                        <Link
                          className="linkitem"
                          to={`/projekte/${project.slug}`}
                        >
                          {!!project.featuredImage.node.localFile && (
                            <GatsbyImage
                              image={getImage(
                                project.featuredImage.node.localFile
                              )}
                              alt={project?.featuredImage?.node?.altText || ""}
                            />
                          )}
                          <div className="innerDiv">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: project.title,
                              }}
                            />
                            <FontAwesomeIcon
                              className="faiicon"
                              icon={faChevronRight}
                            />
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </Masonry>
              </div>
            ),
          },
          {
            menuItem: "Architektur",
            render: () => (
              <div className="container">
                <Masonry
                  className={"grid"}
                  options={{
                    resize: true,
                    transitionDuration: 0,
                    // use outer width of grid-sizer for columnWidth
                    columnWidth: ".grid-sizer",
                    // do not use .grid-sizer in layout
                    itemSelector: ".grid-item",
                    percentPosition: true,
                    gutter: 10,
                  }}
                >
                  {/* .grid-sizer empty element, only used for element sizing */}
                  <div className="grid-sizer"></div>
                  {architektur.map((project, i) => {
                    let gridSize = ""
                    if (project?.projekte?.options?.size === "1x1") {
                      gridSize = "grid-item"
                    }
                    if (project?.projekte?.options?.size === "1x2") {
                      gridSize = "grid-item--width2"
                    }
                    if (project?.projekte?.options?.size === "2x1") {
                      gridSize = "grid-item--width3"
                    }
                    if (project?.projekte?.options?.size === "2x2") {
                      gridSize = "grid-item--width4"
                    }

                    return (
                      <div className={"grid-item " + gridSize} key={i}>
                        <Link
                          className="linkitem"
                          to={`/projekte/${project.slug}`}
                        >
                          {!!project.featuredImage.node.localFile && (
                            <GatsbyImage
                              image={getImage(
                                project.featuredImage.node.localFile
                              )}
                              alt={project?.featuredImage?.node?.altText || ""}
                            />
                          )}
                          <div className="innerDiv">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: project.title,
                              }}
                            />
                            <FontAwesomeIcon
                              className="faiicon"
                              icon={faChevronRight}
                            />
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </Masonry>
              </div>
            ),
          },
          {
            menuItem: "Wettbewerbe",
            render: () => (
              <Tab.Pane key="Wettbewerbe">
                <div className="container">
                  <Masonry
                    className={"grid"}
                    options={{
                      resize: true,
                      transitionDuration: 0,
                      // use outer width of grid-sizer for columnWidth
                      columnWidth: ".grid-sizer",
                      // do not use .grid-sizer in layout
                      itemSelector: ".grid-item",
                      percentPosition: true,
                      gutter: 10,
                    }}
                  >
                    {/* .grid-sizer empty element, only used for element sizing */}
                    <div className="grid-sizer"></div>
                    {wettbewerbe.map((project, i) => {
                      let gridSize = ""
                      if (project?.projekte?.options?.size === "1x1") {
                        gridSize = "grid-item"
                      }
                      if (project?.projekte?.options?.size === "1x2") {
                        gridSize = "grid-item--width2"
                      }
                      if (project?.projekte?.options?.size === "2x1") {
                        gridSize = "grid-item--width3"
                      }
                      if (project?.projekte?.options?.size === "2x2") {
                        gridSize = "grid-item--width4"
                      }

                      return (
                        <div className={"grid-item " + gridSize} key={i}>
                          <Link
                            className="linkitem"
                            to={`/projekte/${project.slug}`}
                          >
                            {!!project.featuredImage.node.localFile && (
                              <GatsbyImage
                                image={getImage(
                                  project.featuredImage.node.localFile
                                )}
                                alt={
                                  project?.featuredImage?.node?.altText || ""
                                }
                              />
                            )}
                            <div className="innerDiv">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: project.title,
                                }}
                              />
                              <FontAwesomeIcon
                                className="faiicon"
                                icon={faChevronRight}
                              />
                            </div>
                          </Link>
                        </div>
                      )
                    })}
                  </Masonry>
                </div>
              </Tab.Pane>
            ),
          },
          {
            menuItem: "Stadtplanung",
            render: () => (
              <div className="container">
                <Masonry
                  className={"grid"}
                  options={{
                    resize: true,
                    transitionDuration: 0,
                    // use outer width of grid-sizer for columnWidth
                    columnWidth: ".grid-sizer",
                    // do not use .grid-sizer in layout
                    itemSelector: ".grid-item",
                    percentPosition: true,
                    gutter: 10,
                  }}
                >
                  {/* .grid-sizer empty element, only used for element sizing */}
                  <div className="grid-sizer"></div>
                  {stadtplanung.map((project, i) => {
                    let gridSize = ""
                    if (project?.projekte?.options?.size === "1x1") {
                      gridSize = "grid-item"
                    }
                    if (project?.projekte?.options?.size === "1x2") {
                      gridSize = "grid-item--width2"
                    }
                    if (project?.projekte?.options?.size === "2x1") {
                      gridSize = "grid-item--width3"
                    }
                    if (project?.projekte?.options?.size === "2x2") {
                      gridSize = "grid-item--width4"
                    }

                    return (
                      <div className={"grid-item " + gridSize} key={i}>
                        <Link
                          className="linkitem"
                          to={`/projekte/${project.slug}`}
                        >
                          {!!project.featuredImage.node.localFile && (
                            <GatsbyImage
                              image={getImage(
                                project.featuredImage.node.localFile
                              )}
                              alt={project?.featuredImage?.node?.altText || ""}
                            />
                          )}
                          <div className="innerDiv">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: project.title,
                              }}
                            />
                            <FontAwesomeIcon
                              className="faiicon"
                              icon={faChevronRight}
                            />
                          </div>
                        </Link>
                      </div>
                    )
                  })}
                </Masonry>
              </div>
            ),
          },
        ]}
      />
    </Wrapper>
  )
}

export default ProjectsGrid
